const courseCard = [
  {
    id: 1,
    img: "Images/image-4.jpg",
    title: "Institution Footprints",
    price: "₹4,999",
    courseUrl:'/course1',
  },
  {
    id: 2,
    img: "Images/image-4.jpg",
    title: "Future & Options",
    price: "₹2,999",
    courseUrl:'/course2'
  },
  {
    id: 2,
    img: "Images/image-4.jpg",
    title: "Combined",
    price: "₹6,999",
    courseUrl:'/course3'
  },
 
];

export default courseCard;
